:root {
  --page-header-padding: 2.375rem;
}

.ContentTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

.ContentTable thead {
  border-bottom: 1px solid var(--mb-color-border);
}

.PageHeader {
  padding-top: var(--page-header-padding);
  padding-bottom: var(--page-header-padding);
}

.TableActions {
  text-align: right;
}

.ContentTable .TableActions {
  opacity: 0;
}

.ContentTable td,
.ContentTable th {
  padding: 1em;
}

.ContentTable th {
  color: var(--mb-color-text-dark);
  padding: 1em;
}

/* TODO: remove this and apply AdminHoverItem to content rows */
.ContentTable tbody tr:hover {
  background-color: var(--mb-color-brand-alpha-04);
}

.ContentTable tr:hover .TableActions {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.AdminList {
  background-color: var(--color-bg-light);
  border: var(--border-size) var(--border-style) var(--mb-color-border);
  border-radius: var(--default-border-radius);
  width: 266px;
  min-height: 300px;
  box-shadow: inset -1px -1px 3px var(--mb-color-shadow);
  padding-bottom: 0.75em;
}

.AdminListSearch {
  position: relative;
}

.AdminListSearch .Icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 1em;
  color: var(--mb-color-text-light);
}

.AdminListSearch .AdminInput {
  padding: 0.5em;
  padding-left: 2em;
  font-size: 18px;
  width: 100%;
  border-top-left-radius: var(--default-border-radius);
  border-top-right-radius: var(--default-border-radius);
  border-bottom-color: var(--mb-color-border);
}

.AdminListItem {
  padding: 0.75em 1em 0.75em 1em;
  border: var(--border-size) var(--border-style) transparent;
  border-radius: var(--default-border-radius);
  margin-bottom: 0.25em;
}

.AdminListItem.selected {
  color: var(--mb-color-brand);
}

.AdminListItem.selected,
.AdminListItem:hover {
  background-color: white;
  border-color: var(--mb-color-border);
  margin-left: -0.5em;
  margin-right: -0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-shadow: 0 1px 2px var(--mb-color-shadow);
}

.AdminListSection {
  margin-top: 1em;
  padding: 0.5em 1em 0.5em 1em;
  text-transform: uppercase;
  color: var(--mb-color-text-light);
  font-weight: 700;
  font-size: smaller;
  padding-right: 15px; /* set so that table visibility icons align */
}

.AdminInput {
  color: var(--mb-color-text-dark);
  padding: var(--padding-1);
  background-color: var(--color-bg-light);
  border: 1px solid transparent;
}

.AdminInput:focus {
  border-color: var(--mb-color-brand);
  box-shadow: none;
  outline: 0;
}

.MetadataTable {
  min-width: 800px;
}

.TableEditorFieldDescription {
  font-size: 14px;
}

.TableEditorFieldSemanticType,
.TableEditorFieldTarget {
  margin-top: 3px;
}

.SettingsInput {
  width: 400px;
}

.SettingsPassword {
  width: 200px;
}

.UserActionsSelect {
  min-width: 180px;
}

.AdminTable {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

.AdminTable th {
  text-transform: uppercase;
  color: var(--mb-color-text-medium);
  padding: var(--padding-1);
  font-weight: normal;
}

.AdminTable thead {
  border-bottom: var(--border-size) var(--border-style) var(--mb-color-border);
}

.AdminTable tbody tr:first-child td {
  padding-top: var(--margin-1);
}
